import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name; 

    constructor(props) {
        super(props)

        this.onClickHandler = this.onClickHandler.bind(this);
    }

    onClickHandler() {
        // TODO: change with better routing
        window.location.href = "/signup";
    }

  render () {
    return (
        <header className="masthead text-white text-center">
            <div className="overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 mx-auto">
                        <h1 className="mb-5">Sign up to send your gratitude to your donor.</h1>
                    </div>
                    <div className="col-12 col-md-6">
                        <button type="submit" className="btn btn-block btn-lg btn-primary" onClick={this.onClickHandler}>Sign up!</button>
                    </div>
                </div>
            </div>
        </header>
    );
  }
}
