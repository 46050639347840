import React, { Component } from 'react';
import { RecipientRegistration } from '../RecipientRegistration/RecipientRegistration';

// Manages the flow of signing up a recipient.  Should have all components needs to full complete a new sign up as sub-components.
// Sub-Components: 
// 1. RecipientRegistration
export class RecipientSignUp extends Component {
    static displayName = RecipientSignUp.name;

    constructor(props) {
        super(props);
        this.state = { registered: false, preferences: false };

        this.registrationHandler = this.registrationHandler.bind(this);
    }

    registrationHandler() {
        this.setState({ registered: true });

        // Donor sign up complete.  State should take over as the user should now be logged in.
        window.location.href = "/gratitude"; // TODO: remove later because the app should see the state change as User Logged In, and should send user to DonorFamily component... or something like that
    }

    render() {
        return (
            <div>
                {
                    // If not registereed render registration
                    this.state.registered === false &&
                    <RecipientRegistration action={this.registrationHandler} />
                }
            </div>
        );
    }
}
