import React, { Component } from 'react';

export class RecipientRegistration extends Component {
    static displayName = RecipientRegistration.name;

    constructor(props) {
        super(props);
        this.state = {
            email: ''
        };


        // This info will be populated by the form, may not need to expect
        // anything to be supplied beforehand.
        // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

        // Initialize with info
        // if (props.email != null) {
        //     this.state.email = props.email;
        //     this.state.donorId = props.donorId;
        // } else {
        //     // Setup mock data
        //     this.data = { email: 'test@gmail.com', donorId: 123456789 };
        //     this.state.email = this.data.email;
        //     this.state.donorId = this.data.donorId;
        // }

        this.handleSubmitBound = event => this.handleSubmit(event);
        this.handleEmailBound = event => this.handleEmailUpdate(event);
        this.handlePasswordUpdateBound = event => this.handlePasswordUpdate(event);
        this.handleConfirmationUpdateBound = event => this.handleConfirmationUpdate(event);
    }

    // Bundles the form data and submits it to the user registration API
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.password && this.state.confirmed_password && 
           (this.state.password === this.state.confirmed_password)) { // Passwords match, register user
            this.props.action(); // Call API that returns result. Upon success reroute to logged in page.
        } else {
            //Present an error message saying that passwords do not match
            let passInput = document.getElementById("passwordInput");
            passInput.setCustomValidity('Password fields must match.');
            passInput.reportValidity();
        }
    }

    /*
        State update Functions
        ----------------------
        Responsible for updating relevant state variables to reflect form contents.

        -- TODO: Error checking in each relevant field for early user feedback
    */
    handleEmailUpdate(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordUpdate(event) {
        let passInput = document.getElementById("passwordInput");
        if (passInput.validity.customError) {
            passInput.setCustomValidity('');
            passInput.reportValidity();
        }
        this.setState({password: event.target.value});
    }

    handleConfirmationUpdate(event) {
        let passInput = document.getElementById("passwordInput");
        if (passInput.validity.customError) {
            passInput.setCustomValidity('');
            passInput.reportValidity();
        }
        this.setState({confirmed_password: event.target.value});
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xl-12 mx-auto">
                        <h1 className="mb-5">Sign up to find out more about your donor.</h1>
                    </div>
                    <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
                        <form onSubmit={this.handleSubmitBound}>
                            <div className="form-row">
                                <div className="col-12 col-md-9 mb-2 mb-md-2">
                                    <label className="h1"> Email </label>
                                    <input type="email" className="form-control form-control-lg" placeholder="Enter your email..." 
                                        onChange={this.handleEmailBound} required />
                                </div>
                                <div className="col-12 col-md-9 mb-2 mb-md-2">
                                    <label className="h1"> Password </label>
                                    <input type="password" className="form-control form-control-lg" placeholder="Password..."
                                            onChange={this.handlePasswordUpdateBound} id="passwordInput" required />
                                </div>
                                <div className="col-12 col-md-9 mb-2 mb-md-2">
                                    <label className="h1"> Confirm Password </label>
                                    <input type="password" className="form-control form-control-lg" placeholder="Confirm Password..."
                                            onChange={this.handleConfirmationUpdateBound} required />
                                </div>
                                <div className="col-12 col-md-6">
                                    <button type="submit" className="btn btn-block btn-lg btn-primary">Sign up!</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}
