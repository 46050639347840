import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { RecipientSignUp } from './components/RecipientSignUp/RecipientSignUp';

// Bootstrap core CSS
import './vendor/bootstrap/css/bootstrap.min.css'

// Custom fonts for this Theme
import './vendor/fontawesome-free/css/all.min.css'
import './vendor/simple-line-icons/css/simple-line-icons.css'

// Custom styles for this Theme
import './css/landing-page.min.css'
import RecipientGratidue from './RecipientGratitude/RecipientSignUp';


export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/signup' component={RecipientSignUp} />
        <Route path='/gratitude' component={RecipientGratidue} />
      </Layout>
    );
  }
}
