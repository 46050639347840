import React, { Component } from 'react';
// Previously contained: Collapse, NavbarBrand, NavbarToggler, NavItem
import { Container, Navbar, NavLink } from 'reactstrap';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
    }
    
  // TODO make the signup button conditional on being signed out
  render () {
    return (
      <header>
        <Navbar className="navbar navbar-light bg-light static-top" light>
          <Container>
            <div className="nav-brand">
                <a href="/">
                    <img src='/branding/Circle of Life - Logo 2.png' alt='Circle of Life' width='75px' height='75px' />
                </a>
            </div>
            <NavLink className="btn btn-primary" href="/">Sign In</NavLink>
          </Container>
        </Navbar>
      </header>

    );
  }
}
